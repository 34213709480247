<template>
  <!-- <div v-if="no_details" class="header-wrapper bg-orange">
    <b-modal id="complete-profile-dash" centered hide-footer hide-header>
      <div class="row">
        <div class="col">
          <h5 class="font-weight-bold">
            {{ "Complete Your Profile" }}
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="text-danger font-14">
            {{
              "Kindly ensure all account details are completed to unlock access to the Legal Writer features."
            }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col align-self-center">
          <b>{{ "Law Firm Name" }} </b>
        </div>
      </div>
      <div class="row row-detail">
        <div class="col align-self-center">
          <b-form-input
            v-model="extra_info.company_name"
            placeholder="Company Name"
            name="law firm name"
            v-validate="'required'"
            autocorrect="off"
            autocomplete="off"
          ></b-form-input>
        </div>
        <span
          class="text-danger text-sm font-14"
          v-show="errors.has('law firm name')"
          >{{ errors.first("law firm name") }}</span
        >
      </div>

      <div class="row mt-3">
        <div class="col align-self-center">
          <b>{{ "Firm Address" }} </b>
        </div>
      </div>
      <div class="row row-detail">
        <div class="col align-self-center">
          <b-form-input
            v-model="extra_info.firm_address_1"
            placeholder="Address Line 1"
            name="address line 1"
            v-validate="'required'"
            autocorrect="off"
            autocomplete="off"
          ></b-form-input>
          <span
            class="text-danger text-sm font-14"
            v-show="errors.has('address line 1')"
            >{{ errors.first("address line 1") }}</span
          >

          <b-form-input
            class="input-address mt-2"
            v-model="extra_info.firm_address_2"
            placeholder="Address Line 2"
            name="address line 2"
            v-validate="'required'"
            autocorrect="off"
            autocomplete="off"
          ></b-form-input>
          <span
            class="text-danger text-sm font-14"
            v-show="errors.has('address line 2')"
            >{{ errors.first("address line 2") }}</span
          >

          <b-form-input
            class="input-address mt-2"
            v-model="extra_info.firm_address_3"
            placeholder="Address Line 3"
            autocorrect="off"
            autocomplete="off"
          ></b-form-input>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col align-self-center">
          <b>{{ "Law Firm Contact Number" }} </b>
        </div>
      </div>
      <div class="row row-detail">
        <div class="col align-self-center">
          <b-form-input
            type="text"
            v-model="extra_info.contact_number"
            placeholder="Contact Number"
            name="contact number"
            v-validate="'required'"
            autocorrect="off"
            autocomplete="off"
          ></b-form-input>
        </div>
        <span
          class="text-danger text-sm font-14"
          v-show="errors.has('contact number')"
          >{{ errors.first("contact number") }}</span
        >
      </div>

      <div class="row mt-4">
        <div class="col">
          <button class="btn btn-primary float-right" @click="saveChanges()">
            {{ "Update Profile" }}
          </button>
        </div>
      </div>
    </b-modal>

    <div class="row">
      <div class="col">
        <p class="text-center my-auto mt-3 w-100 mx-auto">
          {{ "Kindly ensure all " }}
          <u @click="profile" class="cursor-pointer">
            {{ "account details " }}
          </u>
          {{ "are completed to unlock access to the Legal Writer features." }}
        </p>
      </div>
    </div>
  </div> -->

  <div class="row header-wrapper">
    <div class="route__linkpath col-md-10 col-lg-12">
      <div class="mt-3 ms-2 fw-normal">
        <p class="text-muted fw-normal">
          <button
            @click="$router.go(-1)"
            class="material-icons-outlined btn-back"
          >
            arrow_back
          </button>

          <a
            v-for="(crumb, idx) in BreadCrumbs"
            :key="idx"
            class="route__link"
            @click.prevent="breadCrumbNavigate(idx)"
          >
            {{ crumb.text }}
          </a>
        </p>
      </div>
    </div>

    <div class="col-md-2 d-lg-none">
      <div class="d-flex align-items-center py-1 ms-auto">
        <!-- <span class="px-3 header-avatar d-flex align-items-center us-0 d-none d-lg-flex">
        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
      <button class="dropbtn material-icons-outlined">expand_more</button>
        <div class="dropdown-wrapper">
          <div class="dropdown-content">
            <span class="text-muted mb-2" style="overflow: hidden"></span>
            <div class="role me-auto">
              <span></span>
            </div>
            <hr />
            <button @click="profile" class="d-flex align-items-center">
              <span class="material-icons-outlined">person</span>
              <span class="ms-2">Profile</span>
            </button>
            <button class="d-flex align-items-center">
              <span class="material-icons-outlined">settings</span>
              <span class="ms-2">Admin</span>
            </button>
            <button class="d-flex align-items-center">
              <span class="material-icons-outlined">logout</span>
              <span class="ms-2">Logout</span>
            </button>
          </div>
        </div> 
      </span> -->

        <!-- Sidebar Button -->
        <button
          class="btn btn-primary mx-2 d-block d-lg-none"
          @click="toggleMenu"
        >
          <span class="material-icons-outlined"> menu </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import Auth from "@/store/Auth.js";
export default {
  components: {},
  data() {
    return {
      // no_details: false,
      // extra_info: {
      //   contact_number: "",
      //   firm_address_1: "",
      //   firm_address_2: "",
      //   firm_address_3: "",
      //   company_name: JSON.parse(localStorage.userInfo).extra_info.company_name,
      // },
      // not_have_plan_yet: JSON.parse(localStorage.userInfo).not_have_plan_yet,
      isExpanded: false,
      BreadCrumbs: [],
      user: JSON.parse(localStorage.getItem("userInfo")),
    };
  },

  methods: {
    // profile() {
    //   this.$bvModal.show("complete-profile-dash");
    // },

    // localStoreHandler(e) {
    //   if (e.key == "userInfo") {
    //     if (
    //       Object.values(JSON.parse(e.value).extra_info).some(
    //         (value) => value === null
    //       ) ||
    //       Object.values(JSON.parse(e.value).extra_info).some(
    //         (value) => value === ""
    //       ) ||
    //       Object.values(JSON.parse(e.value).extra_info).some(
    //         (value) => value.length === 0
    //       ) ||
    //       Object.values(JSON.parse(e.value).extra_info).some(
    //         (value) => value === 0
    //       )
    //     ) {
    //       this.no_details = true;
    //       // console.log("Here 4");
    //     }
    //   }
    // },

    // saveChanges() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       Auth.updateProfile({
    //         name: JSON.parse(localStorage.userInfo).name,
    //         email: JSON.parse(localStorage.userInfo).email,
    //         extra_info: {
    //           contact_number: this.extra_info.contact_number,
    //           address:
    //             this.extra_info.firm_address_1 +
    //             "\n" +
    //             this.extra_info.firm_address_2 +
    //             "\n" +
    //             this.extra_info.firm_address_3,
    //           company_name: this.extra_info.company_name,
    //         },
    //       })
    //         .then((response) => {
    //           this.$bvModal.hide("complete-profile-dash");
    //           this.$toast.success(response.data.message);

    //           Auth.getMe().then((res) => {
    //             localStorage.setItem("userInfo", JSON.stringify(res.data.data));

    //             const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //             if (
    //               !userInfo ||
    //               userInfo.nexlaw_credits == undefined ||
    //               userInfo.nexlaw_credits == "undefined" ||
    //               userInfo.nexlaw_credits == null ||
    //               userInfo.nexlaw_credits == "null"
    //             ) {
    //               localStorage.removeItem("accessToken");
    //               localStorage.removeItem("userInfo");
    //               this.$router.push("/login");
    //             }
    //           });
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //   });
    // },

    toggleMenu() {
      this.isExpanded = !this.isExpanded;
      localStorage.setItem("leftbar_mobile_toggle", this.isExpanded);
    },

    //
    breadCrumbNavigate(idx) {
      // bypass condition
      if (idx === this.BreadCrumbs.length - 1) return;

      // go to dashboard
      if (idx === 0) this.$router.push({ name: "Dashboard" });

      // go back
      else this.$router.go(idx + 1 - this.BreadCrumbs.length);
    },
  },

  created() {
    this.BreadCrumbs = this.$route.meta.breadCrumb;
    localStorage.setItem("leftbar_mobile_toggle", this.isExpanded);

    // document.addEventListener("localUpdated", this.localStoreHandler, false);

    // if (JSON.parse(localStorage.userInfo).extra_info === null) {
    //   this.no_details = true;
    //   console.log("Here 1");
    // }

    // if (
    //   !(Object.values(JSON.parse(localStorage.userInfo).extra_info).some(
    //     (value) => value === null
    //   ) ||
    //   Object.values(JSON.parse(localStorage.userInfo).extra_info).some(
    //     (value) => value === ""
    //   ) ||
    //   Object.values(JSON.parse(localStorage.userInfo).extra_info).some(
    //     (value) => value.length === 0
    //   ) ||
    //   Object.values(JSON.parse(localStorage.userInfo).extra_info).some(
    //     (value) => value === 0
    //   ))
    // ) {
    //   const userInfo = JSON.parse(localStorage.userInfo);
    //   const addressParts = userInfo.extra_info.address.split("\n");

    //   this.extra_info.contact_number = userInfo.extra_info.contact_number;
    //   this.extra_info.firm_address_1 = addressParts[0];
    //   this.extra_info.firm_address_2 = addressParts[1];
    //   this.extra_info.firm_address_3 = addressParts[2];
    //   this.extra_info.company_name = userInfo.extra_info.company_name;
    // }
  },
};
</script>

<style lang="css" scoped>
.btn-primary {
  background-color: #094890 !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  text-align: center !important;
}

/* .row-detail {
  margin-top: 0.5rem;
} */

/* .btn-primary:hover {
  background-color: #0379e0 !important;
  color: #ffffff;
  border-radius: 6px;
} */
/* .btn-free {
  background-color: #ffffff !important;
  color: #4caf50;
  border-color: #4caf50;
  border-radius: 6px !important;
  text-align: center !important;

}
.btn-free:hover {
  background-color: #4caf50 !important;
  color:white !important;
} */

/* ------------------ */
.header-wrapper {
  position: sticky;
  z-index: 50 !important;
  top: 0;
  border-bottom: var(--color-primary) 1px solid;
  background-color: #ffffff;
  /* height: 67px; */
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
  /* padding: 0rem 4px; */
  height: 68px;
}

/* .header-wrapper-main{
   position: sticky;
  z-index: 50 !important;
  top: 0;
  right:0 !important;

  margin-left: 180px;

  background-color: #ffffff;
  height: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 4px;
} */
/* .header-avatar img {
  vertical-align: middle;
  width: 38px;
  height: 38px;
  border-radius: 5px;
} */

/* .btn-primary-outlined {
  border: 1px solid #094890;
  border-radius: 5px;
  color: #094890;
}

.btn-primary-outlined .text {
  overflow: hidden;
  white-space: nowrap;
}

.btn-primary-outlined:hover {
  background-color: #094890;
  color: white;
  transition: 0.2s ease-out;
} */

/* .btn-view-other {
  background-color: #fff;
  color: #4caf50;
  border: 1px solid #4caf50;
  border-radius: 6px;
  margin-top: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  font-weight: 500;
}

.btn-view-other:hover {
  background-color: #4caf50;
  color: #ffffff;
  transition: 0.4s ease-out;
} */

.route__link {
  text-decoration: none;
  color: #6c757d;
}

.route__link:hover {
  text-decoration: none;
  color: #4f555b;
  text-decoration: underline;
  transition: all 0.5s ease-out;
}

/* ------ account-dropdown------ */
/* .dropdown {
  width: 90px;
} */

/* .dropdown-wrapper {
  display: block;
  transform: translate(-161px, 143px);
  transition: all 0.4s ease-out;
  visibility: visible;
  opacity: 1;
} */

/* .dropdown-wrapper {
  visibility: hidden;
  opacity: 0;
  background-color: transparent;
  padding-top: 1rem;
  // display: none;
  position: absolute;
  width: 230px;
  z-index: 1;
  transform: translate(-161px, 133px);
  border-radius: 10px;
  transition: all 0.1s ease-out;
}

.dropdown-wrapper.no-plan {
  transform: translate(-161px, 75px);
} */

/* .dropdown-wrapper.no-plan {
  transform: translate(-161px, 85px);
} */

/* .dropdown-content {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem 0.8rem;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.dropdown-content span {
  display: inline-block;
}

.dropdown-content .role {
  display: inline-block;
  padding: 0rem 0.5rem;
  border: 1px solid #4caf50;
  border-radius: 5px;
  width: 95px;
}

.dropdown-content .role span {
  font-size: 14px;
  color: #4caf50;
}

.dropdown-content hr {
  margin: 1rem 0 0.3rem;
  width: 100%;
}

.dropdown-content button {
  color: rgb(78, 78, 78);
  padding: 0.5rem;
  text-decoration: none;
  display: block;
  text-align: left;
  margin-top: 2px;
  border-radius: 8px;
  background-color: #fff;
  transition: 0.2s ease-out;
}

.dropdown-content button:hover {
  border-radius: 8px;
  background-color: #dce8f6;
  transition: 0.4s ease-out;
} */

/* @media only screen and (max-width: 768px) {
  .header-wrapper .search {
    display: none !important;
  }

  .upgrade-dropdown-wrapper {
    width: 250px;
    transform: translate(-200px, -8px);
  }

  .upgrade-dropdown:hover .upgrade-dropdown-wrapper {
    transform: translate(-200px, -3px);
  }

  .mobile__horizontalScroll {
    overflow: scroll;
    max-width: 300px;
  }

  .mobile__horizontalScroll::-webkit-scrollbar {
    display: none;
  }
} */

@media only screen and (max-width: 992px) {
  .route__linkpath {
    display: none;
  }

  /* .header-wrapper .search {
    display: none !important;
  } */

  /* .upgrade-dropdown-wrapper {
    width: 250px;
    transform: translate(-200px, -8px);
  }

  .upgrade-dropdown:hover .upgrade-dropdown-wrapper {
    transform: translate(-200px, -3px);
  } */

  /* .mobile__horizontalScroll {
    overflow: scroll;
    max-width: 300px;
  }

  .mobile__horizontalScroll::-webkit-scrollbar {
    display: none;
  } */
}

.bg-orange {
  background: #f8132a;
  color: white;
  font-weight: 600;
  font-size: 18px;
  border-bottom: #f8132a;
}

a {
  cursor: pointer;
}
</style>
