<template>
  <aside class="sidebar-right d-flex">
    <!-- start of Help modal -->
    <b-modal
      id="help-modal"
      title="Get Support"
      title-class="title-sizing"
      centered
      hide-footer
      hide-header-close
    >
      <div class="row py-2 px-2">
        <div class="col-12 col-md-6">
          <h5 class="header mb-3">Contact Us</h5>
          <div class="row">
            <div class="col-12">
              <a href="mailto:info@nexlaw.ai" target="_blank">
                <h6 class="text-blue mx-3 d-inline align-self-center">
                  info@nexlaw.ai
                </h6>
                <span
                  class="material-icons-outlined d-inline align-self-center float-right mx-3 text-blue"
                  >mail_outline</span
                >
              </a>
            </div>
            <div class="col-12 mt-md-2">
              <a
                href="https://www.linkedin.com/company/nexlaw-ai/?viewAsMember=true"
                target="_blank"
              >
                <h6 class="text-blue mx-3 d-inline align-self-center">
                  nexlaw.ai
                </h6>
                <span
                  class="mdi mdi-linkedin mdi-24px align-self-center d-inline float-right mx-3 text-blue"
                ></span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <h5 class="mt-sm-3 mt-md-0 mb-3">Resources</h5>
          <div class="row">
            <div class="col-12">
              <router-link to="/tutorial">
                <h6 class="text-blue mx-3 d-inline align-self-center">
                  Tutorial
                </h6>
                <span
                  class="material-icons-outlined d-inline align-self-center float-right mx-3 text-blue"
                >
                  ondemand_video
                </span>
              </router-link>
            </div>
            <div class="col-12 mt-md-2">
              <a href="https://nexlaw.ai/#faq-contact-us" target="_blank">
                <h6 class="text-blue mx-3 d-inline align-self-center">
                  FAQ
                </h6>
                <span
                  class="material-icons-outlined d-inline align-self-center float-right mx-3 text-blue"
                >
                  contact_support
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end of Help modal -->

    <!-- start of Activity sidebar -->
    <b-sidebar
      id="activity-sidebar"
      title="Activities"
      backdrop
      backdrop-variant="dark"
      right
      width="350px"
    >
      <div
        style="width: 100%; height: 1px; border-bottom: 1px solid lightgray"
      ></div>
      <Activity class="px-4" />
    </b-sidebar>
    <!-- end of Activity sidear -->

    <!-- start of Menu -->
    <div class="menu-bar">
      <!-- *Activity -->
      <button v-if="permissions.length !== 0" v-b-toggle.activity-sidebar>
        <!-- @click="toggleMenu('activity')" -->
        <span id="hoverBtnActivity" class="material-icons-outlined">
          history
        </span>
        <b-popover
          :target="'hoverBtnActivity'"
          triggers="hover"
          placement="left"
          custom-class="sidebar-right__popover activity"
        >Activity Log</b-popover>
      </button>

      <button v-b-modal.help-modal>
        <span id="hoverBtnHelp" class="material-icons-outlined">
          help_outline
        </span>
        <b-popover
          :target="'hoverBtnHelp'"
          triggers="hover"
          placement="left"
          custom-class="sidebar-right__popover help"
        >Help</b-popover>
      </button>
    </div>
  </aside>
</template>

<script>
import Activity from "@/components/SidebarRight-menus/Activity.vue";

export default {
  name: "SidebarRight",
  components: {
    Activity,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.permissions),
    };
  },
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
  text-align: center;
}
a h6 {
  font-size: 18px;
}
a span {
  font-size: 25px;
}

.menu-heading a {
  text-decoration: none;
  color: inherit;
}

.menu-heading a .material-icons-outlined {
  font-size: 1.6rem;
  color: #094890;
}

aside {
  position: sticky;
  z-index: 99;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--sidebar-bg-colour);
  width: 3.5rem;
}

.menu-bar {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 3.5rem;
  min-height: 100%;
  background-color: var(--sidebar-bg-colour);
  padding: 0.7rem 1rem;
  transition: 0.2s ease-out;
  border-left: var(--color-primary) 1px solid;
  align-items: center;
}

.menu-bar button {
  padding: 0.5rem 0.5rem 0.2rem;
  border-radius: 5px;
  margin-bottom: 0.2rem;
}

.menu-bar button .material-icons-outlined {
  font-size: 1.5rem;
  transition: 0.2s ease-out;
  color: var(--color-primary);
  user-select: none;
}

.menu-bar button:hover {
  background-color: #dce8f6;
  transition: 0.4s ease-out;
}

@media only screen and (max-width: 991px) {
  aside {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9999;
    min-height: 20%;
    background-color: transparent;
  }

  .menu-bar {
    position: relative;
    background-color: transparent;
    padding: 0.7rem 0.2rem;
  }

  .menu-bar button {
    padding: 0.5rem 0.2rem 0.2rem;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 35px;
    width: 35px;
  }

  .menu-bar button .material-icons-outlined {
    font-size: 1.3rem;
  }
}
</style>
