<template>
  <div>
    <div
      class="page-wrapper-lg m-0"
    >
      <h3 class="text-blue text-center">
        Choose your plan and continue using NexLaw
        <br />
        to accelerate your legal tasks
      </h3>
      <h3
        v-if="current_plan"
        class="text-blue text-center mb-4 font-weight-bold"
      >
        {{
          `You${ subscribed ? "r current plan is" : "'ve been trialing" }
          ${ current_plan }`
        }}
      </h3>

      <div class="toggle-priceplan mb-4">
        <div class="subtitle"></div>
        <input
          v-model="category"
          id="monthly"
          type="radio"
          name="monthly"
          value="monthly"
        />
        <label for="monthly" class="toggle-priceplan-yearly">
          <span class="text-blue m-auto">Monthly</span>
        </label>
        <input
          v-model="category"
          id="yearly"
          type="radio"
          name="yearly"
          value="yearly"
        />
        <label for="yearly" class="toggle-priceplan-monthly flex-column">
          <span class="text-blue mx-auto">Yearly</span>
        </label>
        <div class="subtitle">- Saved up to $1200</div>
      </div>

      <div class="plan-container">
        <div class="carousel">
          <!-- Navigation -->
          <input
            v-for="n in Math.ceil(plans.length / 2)"
            :key="`carousel-slide-activator-${n}`"
            class="carousel-activator"
            type="radio"
            name="carousel"
            :id="`carousel-slide-activator-${n}`"
            :checked="n === 1"
          />

          <div class="carousel-controls">
            <label for="carousel-slide-activator-2">
              <span class="material-icons-outlined"
                >arrow_circle_right</span
              >
            </label>
          </div>
          <div class="carousel-controls">
            <label for="carousel-slide-activator-1">
              <span class="material-icons-outlined"
                >arrow_circle_left</span
              >
            </label>
          </div>

          <div class="carousel-screen">
            <div class="carousel-track">
              <div
                v-for="(plan, index) in plans"
                :key="'plan' + index"
                class="carousel__item w-50"
              >
                <div
                  :class="[
                    'nexcard plan-card',
                    { 'popular-plan': plan.name == 'Complete' }, // change Popular Plan here (if needed)
                  ]"
                >
                  <div class="popular-tag">
                    <b>Popular</b>
                  </div>

                  <div
                    class="d-flex flex-column plan-content"
                    :style="planContentStyle[index]"
                  >
                    <!-- Plan Overview -->
                    <div class="row">
                      <div v-if="plan.id == 'custom'" class="col text-muted">
                        <div v-html="plan.heading"></div>
                      </div>
                      <div v-else class="col ori-price">
                        ${{ plan.original_price[category] }}
                      </div>
                    </div>
                    <div class="row" v-if="plan.id !== 'custom'">
                      <div class="col currency">
                        <b class="price"> ${{ plan.price[category] }} </b>USD /
                        month
                      </div>
                    </div>
                    <div class="row">
                      <div class="col plan-name">
                        <b>{{ plan.name }}</b>
                      </div>
                    </div>

                    <hr class="my-2" />

                    <!-- Plan Perks -->
                    <div class="row">
                      <div class="col">
                        <button
                          v-b-toggle="'plan-perks-' + index"
                          class="py-2 w-100 d-flex justify-content-between"
                        >
                          <b>Subscription Benefits:</b>
                          <!-- <span class="material-icons-outlined arrow-icon">
                            expand_less
                          </span> -->
                        </button>
                      </div>
                    </div>
                    <!-- <b-collapse
                      :id="'plan-perks-' +
                        index"> -->
                    <ul>
                      <li
                        v-for="(perk, index) in plan.perks"
                        :key="index"
                        :class="[
                          'text-normal tick',
                          { 'text-hl': perk[1]?.split(' ').includes('hl') },
                        ]"
                      >
                        <div
                          v-if="perk[1]?.split(' ').includes('tooltip')"
                          :id="'tooltip-enabled-perk-' + index"
                          class="tooltip-enabled-perk"
                          style="
                            cursor: help;
                            text-decoration-style: dotted;
                            text-decoration-line: underline;
                          "
                          v-html="perk[0]"
                        ></div>
                        <div v-else v-html="perk[0]"></div>

                        <small
                          v-if="perk[1]?.split(' ').includes('small')"
                          class="text-muted"
                        >
                          {{ perk[2] }}
                        </small>

                        <b-collapse :id="'details-' + index">
                          <ul
                            v-if="perk[1]?.split(' ').includes('extra')"
                            class="mt-2 normal-list"
                          >
                            <li v-html="perk[3][0]"></li>
                          </ul>
                        </b-collapse>

                        <button
                          v-if="perk[1]?.split(' ').includes('extra')"
                          v-b-toggle="'details-' + index"
                          class="detail-btn text-muted"
                          @click="
                            $set(
                              perk[3],
                              1,
                              perk[3][1] == '...See More'
                                ? '...See Less'
                                : '...See More'
                            )
                            adjustPlanHeight()
                          "
                        >
                          {{ perk[3][1] }}
                        </button>

                        <b-tooltip
                          v-if="perk[1]?.split(' ').includes('tooltip')"
                          :target="'tooltip-enabled-perk-' + index"
                          id="subscribe-perk-tooltip"
                          placement="topright"
                          noninteractive
                        >
                          {{ perk[4] }}
                        </b-tooltip>
                      </li>
                    </ul>
                    <!-- </b-collapse> -->
                  </div>
                
                  <div>
                    <div style="height: 60px"></div>
                    <a
                      v-if="plan.id == 'custom'"
                      :href="plan.link"
                      target="_blank"
                    >
                      <button class="btn-subscribe-basic">Contact Sales</button>
                    </a>
                    <button
                      v-else
                      @click="payPage(plan.plan_id, plan.price[category])"
                      class="btn-subscribe-basic"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      plans: [
        {
          // price id will be equal to
          // plan_id + 1 if it is yearly
          plan_id: 500,
          name: "Essential",
          original_price: {
            monthly: 249,
            yearly: 149,
          },
          price: {
            monthly: 119,
            yearly: 89,
          },
          perks: [
            ["1 attorney"],
            [
              "10 free credits for the <b>Legal AI Trial Copilot</b> that streamlines case preparation",
              "hl",
            ],
            [
              "Up to <b>500 credits</b> for our complete legal suite: <b>Legal Research & Legal Argument, Case Law Search, Legislation Search, Case Summarizer, Contract Review, Legal Writer</b> and <b>Law Bot Access<b>",
              "small",
              "(Each feature usage costs just 1 credit)",
            ],
            [
              "<b>American Law</b> (50-state and federal cases, statutes, regulations, and rules)",
            ],
            [
              "<b>Common Law</b> (Australia, New Zealand, UK, Canada, Singapore, and Malaysia)",
            ],
            ["Premium Customer Support and Personalized Training"],
          ],
        },
        {
          plan_id: 510,
          name: "Complete",
          original_price: {
            monthly: 399,
            yearly: 299,
          },
          price: {
            monthly: 199,
            yearly: 169,
          },
          perks: [
            [
              "1-3 attorney(s)",
              "tooltip",
              null,
              null,
              "All attorneys(1-3) must have email address from the same domain",
            ],
            [
              "<b>Legal AI Trial Copilot</b> that <b>tailored</b> to excel in your case, aids in <b>preparing pleadings, trial strategy derived from jury instructions, litigation tactics,</b> and assists in <b>trial proceedings</b>",
              "hl extra",
              null,
              [
                "Streamlines case preparation by <b>analyzing your specific case particulars,</b> generating comprehensive <b>legal analysis reports</b> covering various aspects of the case, including <b>applicable laws, key elements, strengths and weaknesses of the case, strategy with case, witness analysis,</b> and more",
                "...See More",
              ],
            ],
            [
              "Our complete legal suite: <b>Legal Research & Legal Argument, Case Law Search, Legislation Search, Case Summarizer, Contract Review, Legal Writer</b> and <b>Law Bot Access</b>",
            ],
            [
              "<b>American Law</b> (50-state and federal cases, statutes, regulations, and rules)",
            ],
            [
              "<b>Common Law</b> (Australia, New Zealand, UK, Canada, Singapore, and Malaysia)",
            ],
            ["Premium Customer Support and Personalized Training"],
          ],
        },
        {
          id: "custom",
          heading: "Need a custom plan?<br>Get a quote now",
          name: "Enterprise",
          perks: [
            ["Tailored solutions for any size of law firm"],
            ["Include everything you get from complete plan"],
            [
              "Personalized onboarding for your specific needs and team management",
            ],
            ["Co-branding and partnership"],
          ],
          link: "https://www.nexlaw.ai/trial-copilot/",
        },
        // {
        //   id: "custom",
        //   heading: "For Education &<br>Non-profits Organization",
        //   name: "Custom Solutions",
        //   height: "220px",
        //   perks: [
        //     ["We offer special discounts for law schools and non-profits"],
        //     ["Research support for students and members"],
        //     ["Scholarly Collaboration"],
        //   ],
        //   link: "https://www.nexlaw.ai/education-and-non-profit/",
        // },
      ],
      category: "yearly",

      subscribed: null,
      current_plan: null,
    };
  },

  computed: {
    planContentStyle() {
      return this.plans
        .map(plan => {
          return {'height':
            plan.height ? `${plan.height}px`
            : plan?.minHeight ? `${plan.minHeight}px` 
            : 'auto'};
        });
    }
  },

  methods: {
    payPage(plan_id, price) {
      const paramsObj = {
        period: this.category,
        plan_id: JSON.stringify(plan_id),
        plan_price_id: JSON.stringify(
          plan_id + (this.category === "yearly")
        ),
        price: price,
      };

      // console.log(paramsObj);
      // return;

      this.$router.push({
        name: "pricing_subscribe",
        params: paramsObj,
      });
    },

    adjustPlanHeight() {
      for (let i=0; i < this.plans.length; i++) {
        this.$delete(this.plans[i], "height");
      }

      setTimeout(() => {
        const planContent = document.querySelectorAll(".plan-content");

        var height = 0;
        planContent.forEach((content, index) => {
          if (index % 2 == 1) {
            this.$set(
              this.plans[index],
              "height",
              (height > content.offsetHeight
                ? height
                : content.offsetHeight)
            );
            this.$set(this.plans[index-1], "height", this.plans[index].height);

            if(this.plans[index].minHeight || this.plans[index-1].minHeight) return;

            if (height > content.offsetHeight ) {
              this.$set(this.plans[index], "minHeight", this.plans[index].height);
            } else {
              this.$set(this.plans[index-1], "minHeight", this.plans[index].height);
            }
          } else {
            height = content.offsetHeight;
            this.$set(this.plans[index], "height", height);
          }
        });
      }, 300);
    },
  },

  created() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    this.subscribed = userInfo.not_have_plan_yet === null
      ? true
      : userInfo.has_subscription;

    let plan = userInfo.current_plan_price.id;
    this.current_plan =
      `${ plan % 2 ? "Yearly" : "Monthly" }
      ${ [500, 501].includes(plan)
          ? "Essential Plan"
        : [510, 511].includes(plan)
          ? "Complete Plan"
          : "[Plan Name]" }`
  },

  mounted() {
    this.adjustPlanHeight();
  },
};
</script>

<style lang="css" scoped>
/* year/month toggle */
.toggle-priceplan {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-priceplan input {
  display: none;
  translate: 2rem 0rem;
}

.toggle-priceplan label {
  display: flex;
  justify-content: center;
  border: 3px solid #094890;
  width: 200px;
  height: 40px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

.toggle-priceplan input:checked + label {
  background-color: #094890;
}

.toggle-priceplan input:checked + label span {
  color: #fff !important;
}

.toggle-priceplan-yearly {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: 0px !important;
}

.toggle-priceplan-monthly {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: 0px !important;
}

.subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  font-size: large;
  font-weight: bolder;
  color: orangered;
}

/* plan styling */
.plan-card {
  position: relative;
  padding: 1.5rem;
  width: 100%;
  height: 100%;
  box-shadow: rgba(0,0,0,0.2) 0px 0px 10px !important;
}

.popular-tag {
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 12px;
  border-radius: 0px 10px;
  background-color: var(--color-primary);
  color: white;
  box-shadow: 5px 5px 10px #ccc;
}

.popular-plan .popular-tag {
  display: flex;
}

.plan-card .ori-price {
  font-size: 25px;
  text-decoration: line-through;
  color: gray;
}

.plan-card .currency {
  font-size: 20px;
}

.plan-card .price,
.plan-card .plan-name {
  font-size: 35px;
}

.popular-plan .plan-name {
  color: var(--color-primary);
}

/* .collapse-btn {
  padding-left: 10px;
  border-radius: 10px;
  transition: 0.4s ease-out;
}

.collapse-btn:hover {
  background-color: #dce8f6;
}

.collapse-btn .arrow-icon {
  margin-left: 0.2rem;
  margin-right: 0.5rem;
}

.collapse-btn[aria-expanded="true"] .arrow-icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

.collapse-btn[aria-expanded="false"] .arrow-icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
} */

.plan-content {
  transition: all 0.25s ease 0s;
}

.text-hl {
  color: var(--color-primary);
}

ul .tick {
  list-style-image: url("../../assets/icons/bullet-icon-tick-green.svg");
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}

ul .tick::marker {
  margin-top: 50px !important;
}

.normal-list {
  list-style-type: disc;
  list-style-image: none;
}

.detail-btn {
  padding-left: 0px;

  &:hover {
    text-decoration: underline;
  }
}

.btn-subscribe-basic {
  position: absolute;
  left: 15%;
  bottom: 1.5rem;
  background-color: #094890;
  padding: 0.8rem 1rem;
  border-radius: 10px;
  border: 2px solid #094890;
  width: 70%;
  color: #ffffff;
  font-weight: 600;
  margin: auto;
  transition: 0.4s ease-out;
}

.btn-subscribe-basic:hover {
  background-color: #094890;
  color: #ffffff;
  transition: 0.2s ease-out;
  box-shadow: 0 15px 20px 0 #0948909d;
  transform: translateY(-7px);
}

/* plan-carousel */
.plan-container {
  min-height: 200px;
}

.carousel {
  max-width: 68.7vw;
  margin-left: auto;
  margin-right: auto;
  padding-right: 80px;
  position: relative;
}

.carousel-activator {
  display: none;
}

.carousel-controls {
  display: none;
  position: absolute;
  top: 80px;
  right: 0px;
  bottom: 0;
}

/* not sure if it is mandatory */
/* .carousel-controls:first-of-type {
  justify-content: flex-end;
}
.carousel-controls:last-of-type {
  justify-content: flex-start;
} */

.carousel-controls label {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #3d414a;
  border-radius: 100%;
  box-shadow: 0 2px 10px 0 rgba(33, 34, 36, 0.3);
  font-size: 24px;
  height: 48px;
  justify-content: center;
  transition: 0.3s all;
  width: 48px;
  z-index: 1;
}

.carousel-controls label:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.carousel-controls label span {
  font-size: 3.5rem;
  text-align: center;
  color: #0056a2;
}

/* populate if needed */
.carousel-activator:nth-of-type(1):checked
  ~ .carousel-controls:nth-of-type(1) {
  display: flex;
}

.carousel-activator:nth-of-type(1):checked
  ~ .carousel-screen
  .carousel-track {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.carousel-activator:nth-of-type(2):checked
  ~ .carousel-controls:nth-of-type(2) {
  display: flex;
}

.carousel-activator:nth-of-type(2):checked
  ~ .carousel-screen
  .carousel-track {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
/* ----- */

.carousel-screen {
  overflow: hidden;
  margin-left: -16px;
  margin-right: -16px;
}

.carousel-track {
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.carousel__item {
  display: inline-flex;
  vertical-align: top;
  white-space: normal;
  padding: 16px;
}
</style>

<style>
#subscribe-perk-tooltip .tooltip-inner {
  max-width: 300px;
}
</style>
