<template>
  <aside :class="isExpanded && 'is-expanded'">
    <router-link
      to="/dashboard"
      class="sidebar-logo d-none d-lg-flex logo-shift"
    >
      <img src="../assets/nexmind/nexLaw_Blue_Logo.png" alt="Nexlaw" />
    </router-link>

    <div class="menu-wrapper">
      <div class="menu d-flex">
        <!-- NoPlanTABS -->
        <div
          v-if="not_have_plan_yet == 1"
          class="mb-auto sidebar-content"
        >
          <router-link
            v-for="(c_key, c_index) in Object.keys(NoPlanTABS)"
            :key="`NP${c_index}`"
            :class="['button', { 'active': pathCheck[c_key] }]"
            :to="NoPlanTABS[c_key].path"
          >
            <span class="material-icons-outlined">{{ NoPlanTABS[c_key].icon }}</span>
            <span class="text">{{ c_key }}</span>
          </router-link>
        </div>

        <!-- TABS -->
        <div
          v-else
          class="mb-auto sidebar-content"
        >
          <div
            v-for="(c_key, c_index) in Object.keys(TABS)"
            :key="c_index"
          >
            <!-- 1st Layer Component // Dashboard -->
            <router-link
              v-if="TABS[c_key].type === 'button'"
              :class="['button', { 'active': pathCheck[c_key] }]"
              :to="TABS[c_key].path"
            >
              <span class="material-icons-outlined">{{ TABS[c_key].icon }}</span>
              <span class="text">{{ c_key }}</span>
            </router-link>

            <!-- 2nd Layer Wrapper -->
            <div
              class="menu-collapsible"
              v-else-if="TABS[c_key].type === 'group'"
              v-show="(c_key === 'Settings') == pathCheck['Settings']"
            >

              <button
                class="button-collapsible"
                data-toggle="collapse"
                :data-target="`#collapse${c_index}`"
                :aria-controls="`collapse${c_index}`"
                aria-expanded="true"
                type="button"
              >
                <span class="text">{{ c_key }}</span>
                <span class="material-icons-outlined arrow-icon">expand_less</span>
              </button>

              <div
                :id="`collapse${c_index}`"
                class="menu-collapse collapse show mt-1"
              >
                <div
                  v-for="([i0_key, i0_val], i0_index) in Object.entries(TABS[c_key].items)"
                  :key="`${c_index}-${i0_index}`"
                >
                  <!-- 2nd Layer Component -->
                  <router-link
                    :class="['position-relative button', { 'active': pathCheck[i0_key] }]"
                    :to="i0_val.path"
                    v-if="
                      // check if type is 'button'
                      i0_val.type === 'button' && 
                      // check if there is a permission_key and user have required permission
                      (!i0_val.permission_key || permissions.includes(i0_val.permission_key)) &&
                      // check if there is a quota_key and user have sufficient quota
                      (!i0_val.quota_key || (quota[i0_val.quota_key] > 0 || quota[i0_val.quota_key] === 'unlimited')) &&
                      // check if there is extra condition to handle
                      (i0_val.condition !== false)
                  ">
                    <span class="material-symbols-outlined">{{ i0_val.icon }}</span>
                    <span :class="[
                      'text',
                      { 'font-weight-bold': i0_key == 'Legal AI Trial Copilot' }
                    ]">
                      {{ i0_key }}
                    </span>

                    <!-- Change NEW badge position here -->
                    <span
                      v-if="i0_key === 'Legal AI Trial Copilot'"
                      :class="[
                        'position-absolute top-0 end-0 badge px-1 NEW-badge',
                        pathCheck[i0_key]
                          ? 'bg-lightblue text-primary'
                          : 'bg-primary',
                      ]"
                    >NEW</span>
                  </router-link>

                  <!-- 3rd Layer Wrapper -->
                  <template v-else-if="i0_val.type === 'group' && i0_val.condition !== false">
                    <a
                      :id="`${i0_key.replace(/ /g, '-')}-toggle`"
                      class="button text"
                      data-toggle="collapse"
                      :data-target="`#collapse${c_index}-${i0_index}`"
                      :aria-controls="`collapse${c_index}-${i0_index}`"
                      aria-expanded="false"
                      type="button"
                    >
                      <span class="material-symbols-outlined">{{ i0_val.icon }}</span>
                      <span class="text">{{ i0_key }}</span>
                    </a>

                    <div
                      :id="`collapse${c_index}-${i0_index}`"
                      class="menu-collapse collapse mb-1"
                    >
                      <div
                        v-for="([i1_key, i1_val], i1_index) in Object.entries(i0_val.items)"
                        :key="`${c_index}-${i0_index}-${i1_index}`"
                      >
                        <router-link
                          v-if="
                            i1_val.type === 'button' &&
                            permissions.includes(i1_val.permission_key) &&
                            (quota[i1_val.quota_key] > 0 || quota[i1_val.quota_key] == 'unlimited')
                          "
                          :class="['button', { 'active': pathCheck[i1_key] }]"
                          :to="i1_val.path"
                        >
                          &nbsp;&nbsp;&nbsp;
                          <span class="material-symbols-outlined">{{ i1_val.icon }}</span>
                          <span class="text">{{ i1_key }}</span>
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar-bottom-overlay w-100 bg-white">
          <!-- Sidebar Bottom Buttons -->
          <div class="mb-auto sidebar-content">
            <router-link
              v-if="not_have_plan_yet != 1"
              :class="['button', { 'active': pathCheck['Settings'] }]"
              to="/settings/profile"
            >
              <span class="material-icons-outlined">manage_accounts</span>
              <span class="text">{{ "Settings" }}</span>
            </router-link>

            <router-link
              v-else
              :class="['button', { 'active': pathCheck['Settings'] }]"
              to="/settings/profile"
            >
              <span class="material-icons-outlined">person</span>
              <span class="text">{{ "Profile" }}</span>
            </router-link>

            <div class="button" @click="logout()">
              <span class="material-icons-outlined">power_settings_new</span>
              <span class="text">{{ "Logout" }}</span>
            </div>
          </div>

          <!-- Remaining Credits -->
          <!-- <div
            class="px-3"
            v-if="
              !(pathCheck['Settings'] || pathCheck['Dashboard'] ||
              pathCheck['Tutorial'] || pathCheck['Pricing']) &&
              quota.statement !== 'unlimited'
          ">
            <div
              v-if="pathCheck['Other Credits']"
              class="credit__box"
            >
              <span class="text-primary fw-600">
                {{ "Remaining Credits" }}
              </span>
              <div v-for="(item, index) in otherCredits" :key="index">
                <small class="mt-2 text-primary">{{ item.title }}</small>
                <div class="bg-primary rounded py-1 mt-1 mb-1">
                  <span class="text-white fw-600">
                    {{ item.free_quota }} / {{ item.full_quota }}
                  </span>
                </div>
              </div>
            </div>

            <div
              v-else-if="quota.statement !== 'unlimited'"
              class="credit__box"
            >
              <span class="text-primary fw-600">
                {{ "Remaining Credits" }}
              </span>
              <div>
                <small class="mt-2 text-primary">{{ route.title }}</small>
                <div class="bg-primary rounded py-1 mt-1 mb-1">
                  <span class="text-white fw-600"
                    >{{ route.free_quota }} / {{ route.full_quota }}</span
                  >
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
/* eslint-disable */
export default {
  name: "SidebarLeft",
  data() {
    return {
      isExpanded: false,
      route: {
        title: "",
        free_quota: 10,
        full_quota: 10,
        used_quota: 10,
      },
      otherCredits: [],
      quota: JSON.parse(localStorage.userInfo).nexlaw_credits.full,
      permissions: localStorage.permissions,
      not_have_plan_yet: JSON.parse(localStorage.userInfo).not_have_plan_yet,

      // tab data
      NoPlanTABS: {
        "Dashboard": {
          path: "/dashboard",
          icon: "home",
        },
        "Tutorial": {
          path: "/tutorial",
          icon: "ondemand_video",
        },
        "Pricing": {
          path: "/pricing",
          icon: "shoppingmode",
        },
      },
      TABS: {
        "Dashboard": {
          type: "button",
          path: "/dashboard",
          icon: "home",
        },
        "Law Features": {
          type: "group",
          items: {
            "Legal AI Trial Copilot": {
              type: "button",
              permission_key: "nexlaw.les",
              quota_key: "les",
              path: "/Legal_Copilot",
              icon: "gavel",
            },
            "Legal Research": {
              type: "group",
              icon: "plagiarism",
              items: {
                "Legal Argument": {
                  type: "button",
                  permission_key: 'nexlaw.legal-argument',
                  quota_key: "legal_argument",
                  path: "/Legal_Argument",
                  icon: "contract_edit",
                },
                "Legal Memo / Opinion": {
                  type: "button",
                  permission_key: 'nexlaw.legal-argument',
                  quota_key: "legal_argument",
                  path: "/Legal_Memo_Opinion",
                  icon: "contract_edit",
                },
              },
            },
            "Case Law Summarizer": {
              type: "button",
              permission_key: "nexlaw.case-summarizer",
              quota_key: "case_summarize",
              path: "/Case_Summarizer/Judgment_File",
              icon: "description",
            },
            "Case Law Search": {
              type: "button",
              permission_key: "nexlaw.case-search",
              quota_key: "statement",
              path: "/Case_Law_Search/Search_Result",
              icon: "search",
            },
            "Legislation Search": {
              type: "button",
              permission_key: "legislation-search",
              quota_key: "legislation",
              path: "/Legislation_Search/Search_Result",
              icon: "quick_reference_all",
            },
            "Legal Compose": {
              type: "group",
              icon: "edit_document",
              items: {
                "Legal Writer": {
                  type: "button",
                  permission_key: "nexlaw.letter-of-demand",
                  quota_key: "lod",
                  path: "/Legal_Writer",
                  icon: "contract_edit",
                },
                "Contract Review": {
                  type: "button",
                  permission_key: "nexlaw.contract-review",
                  quota_key: "contract_review",
                  path: "/Contract_Review/Choose_Party",
                  icon: "contract_edit",
                }
              },
            }
          },
        },
        "Settings": {
          type: "group",
          items: {
            "Profile": {
              type: "button",
              path: "/settings/profile",
              icon: "person"
            },
            "Clients": {
              // should be displayed properly,
              // but since i dont have access to this part on any of my account,
              // kindly help verify this part for me
              type: "button",
              permission_key: "Saas-Client.index",
              path: "/settings/clients",
              icon: "supervised_user_circle"
            },
            "Billing Details": {
              type: "button",
              path: "/settings/billing_details",
              icon: "payment",
            },
          },
        }
      },
    };
  },

  computed: {
    pathCheck() {
      const Mapping = {
        // General
        "Dashboard": `dashboard`,
        "Legal AI Trial Copilot": `Legal_Copilot`,
        "Legal Argument": `Legal_Argument|category=Legal%20Argument`,
        "Legal Memo / Opinion": `Legal_Memo_Opinion|category=Legal%20Research%20Memo`,
        "Case Law Summarizer": `Case_Summarizer`,
        "Case Law Search": `Case_Law_Search`,
        "Legislation Search": `Legislation_Search`,
        "Legal Writer": `Legal_Writer`,
        "Contract Review": `Contract_Review`,

        // Settings
        "Settings": `settings`,
        "Profile": `profile`,
        "Clients": `clients`,
        "Billing Details": `billing_details`,
        "Tutorial": `tutorial`,
        "Pricing": `pricing`,

        // Misc
        "Contract Document": "Contract_Review\/Contract_Document",
        
        "Other Credits": `LegalResearchAISuggestion|\/Case_Summary|LegalResearchSummary\/|Contract_Review\/Contract_Document`,
        "Q Bot": `\/Case_Summary|LegalResearchSummary\/|Contract_Review\/Contract_Document`,
        "Legal Research": `Legal_Argument|Legal_Memo_Opinion|LegalResearchAISuggestion`,
        "Legal Research AI": `LegalResearchAISuggestion`,
        "Legal Compose": `Legal_Writer|Contract_Review`,
      };

      const Processed = {};
      Object.keys(Mapping).forEach(key => {
        Processed[key] = new RegExp(Mapping[key])
          .test(this.$route.fullPath);
      });
      return Processed;
    },
  },

  methods: {
    // use only by the wrapper below
    creditAssign(FULL, USED, title, credit_key) {
      this.route.title = title;
      this.route.full_quota = FULL[credit_key];
      this.route.used_quota = USED[credit_key];
      this.route.free_quota = FULL[credit_key] - USED[credit_key];
    },
    creditAssignWrapper(src) {
      const fullPath = this.$route.fullPath;
      const FULL = JSON.parse(src).nexlaw_credits.full;
      const USED = JSON.parse(src).nexlaw_credits.used;

      // using Qbot credits
      if (this.pathCheck["Q Bot"]) {
        // change title
        var title = "Case Summary LawBot";
        if (fullPath.includes("Contract_Review/Contract_Document"))
          title = "Contract LawBot";

        this.otherCredits.push({
          title: title,
          full_quota: FULL.qbot_questions,
          free_quota: USED.qbot_questions,
          used_quota: FULL.qbot_questions - USED.qbot_questions,
        });
      }

      // using contract improvement credits
      if (
        ["Contract_Review/Contract_Document"].some((path) =>
          fullPath.includes(path)
        )
      ) {
        this.otherCredits.push({
          title: "Contract Improvement",
          full_quota: FULL.contract_improvments,
          used_quota: USED.contract_improvments,
          free_quota: FULL.contract_improvments - USED.contract_improvments,
        });
      }

      // using legal argument doc/summary credits
      if (this.pathCheck["Legal Research AI"]) {
        const legal_argument_documents = {
          title: "Document Upload",
          full_quota: FULL.legal_argument_documents,
          free_quota: USED.legal_argument_documents,
          used_quota:
            FULL.legal_argument_documents - USED.legal_argument_documents,
        };
        const legal_argument_summarize_discussion_case = {
          title: "Case Summarize",
          full_quota: FULL.legal_argument_summarize_discussion_case,
          used_quota: USED.legal_argument_summarize_discussion_case,
          free_quota:
            FULL.legal_argument_summarize_discussion_case -
            USED.legal_argument_summarize_discussion_case,
        };
        const legal_argument_summarize_document = {
          title: "Document Summarize",
          full_quota: FULL.legal_argument_summarize_document,
          used_quota: USED.legal_argument_summarize_document,
          free_quota:
            FULL.legal_argument_summarize_document -
            USED.legal_argument_summarize_document,
        };
        this.otherCredits.push(
          legal_argument_documents,
          legal_argument_summarize_discussion_case,
          legal_argument_summarize_document
        );
      }

      // path-secific credits
      if (fullPath.includes("Case_Law_Search/Cases_jurisdiction")) {
        this.creditAssign(FULL, USED, "Case Summary", "jurisdiction_summarizing");
      } else if (fullPath.includes("Case_Law_Search")) {
        this.creditAssign(FULL, USED, "Case Law Search", "statement");
      } else if (fullPath.includes("Case_Summarizer")) {
        this.creditAssign(FULL, USED, "Case Law Summarizer", "case_summarize");
      } else if (fullPath.includes("Legal_Writer/LegalWriterEdit")) {
        this.creditAssign(FULL, USED, "Legal Writer Summary", "summarize_lod");
      } else if (fullPath.includes("Legal_Writer")) {
        this.creditAssign(FULL, USED, "Legal Writer", "lod");
      } else if (this.pathCheck["Legal Research"]) {
        this.creditAssign(FULL, USED, "Legal Research", "legal_argument");
      } else if (fullPath.includes("Contract_Review")) {
        this.creditAssign(FULL, USED, "Contract Review", "contract_review");
      } else if (fullPath.includes("Legislation_Search/Search_Result")) {
        this.creditAssign(FULL, USED, "Legislation Search", "legislation");
      } else if (fullPath.includes("Legislation_Search")) {
        this.creditAssign(FULL, USED, "Legislation Summary", "legislation_summarizing");
      } else if (fullPath.includes("Legal_Copilot")) {
        // Pre Trial
        this.creditAssign(FULL, USED, "Legal AI Pre-Trial Copilot", "les");

        // Trial
        if (
          fullPath.includes("Legal_Copilot/Trial_Input") ||
          fullPath.includes("/Trial")
        ) {
          this.creditAssign(FULL, USED, "Legal AI Trial Copilot", "les_trial");
        }
      }
    },

    localStoreHandler(e) {
      // console.log(e);
      if (e.key == "leftbar_mobile_toggle") {
        this.isExpanded = e.value;
      } else if (e.key == "userInfo") {
        this.otherCredits = [];
        this.creditAssignWrapper(e.value);
      }
    },

    toggleCollapsible(flag) {
      flag = flag.split(" ");
      flag.push("toggle");
      document.getElementById(flag.join("-")).click();
    },

    logout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("permissions");
      localStorage.removeItem("me");
      this.$router.push("/login");
    },
  },

  watch: {
    $route() {
      this.otherCredits = [];
      this.creditAssignWrapper(localStorage.userInfo);
    },
  },

  created() {
    const localStore = localStorage.setItem;

    localStorage.setItem = function (key, value) {
      const event = new Event("localUpdated");
      event.key = key;
      event.value = value;

      document.dispatchEvent(event);
      localStore.apply(this, arguments);
    };

    const userInfo = JSON.parse(localStorage.userInfo);
    this.TABS["Settings"].items["Billing Details"].condition = false;
    if (
      ["nexmind.ai", "nexlaw.ai"].includes(userInfo.email.split("@")[1]) &&
      userInfo.not_have_plan_yet == null
    ) {
      this.TABS["Settings"].items["Billing Details"].condition = true;
    }

    if (["kleung@landers.com.au"].includes(userInfo.email)) {
      this.TABS["Law Features"].items["Legal Compose"].condition = true;
    } else {
      this.TABS["Law Features"].items["Legal Compose"].condition = false;
    }

    document.addEventListener("localUpdated", this.localStoreHandler, false);
    this.creditAssignWrapper(localStorage.userInfo);

    /**
     * the new CreditAssignWrapper is functioning &
     * no issue has been found within 2 last months so the legacy code is removed.
     */
  },

  mounted() {
    if (this.pathCheck["Legal Research"]) {
      this.toggleCollapsible("Legal Research");
    } else if (this.pathCheck["Legal Compose"]) {
      this.toggleCollapsible("Legal Compose");
    }
  },
};
</script>

<style lang="css" scoped>
aside {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  width: 250px !important;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--sidebar-bg-colour);
  transition: 0.2s ease-out;
  border-right: var(--color-primary) 1px solid;
  display: flex;
  flex-direction: column;
}

.text-primary {
  color: var(--color-primary) !important;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.sidebar-logo {
  margin: 1rem 0rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  user-select: none;
}

.sidebar-logo img {
  height: 60px;
  object-fit: contain;
}

.menu-wrapper {
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 11rem;
}

.menu-wrapper::-webkit-scrollbar {
  display: none;
}

.collapse.show {
  visibility: visible;
  display: block;
}

.button-collapsible .arrow-icon {
  margin-left: 0.2rem;
  margin-right: 0.5rem;
}

.button-collapsible[aria-expanded="true"] .arrow-icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

.button-collapsible[aria-expanded="false"] .arrow-icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

.arrow-icon {
  display: none;
  display: block;
}

.is-expanded .arrow-icon {
  display: block;
}

.menu-toggle {
  transition: 0.2s ease-out;
}

.menu .sidebar-content .material-icons-outlined,
.menu .sidebar-content .material-symbols-outlined {
  font-size: 1.6rem;
  transition: 0.2s ease-out;
  color: #094890;
  user-select: none;
}

.menu {
  margin: 0 -1rem;
  display: flex;
  flex-direction: column;
}

.menu .button {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  transition: 0.2 ease-out;
  margin: 0.5rem 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.menu .button .text {
  font-weight: bold;
  transition: 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  color: #707070;
  color: #094890;
  margin-left: 1rem;
}

.button:hover,
.button-collapsible:hover {
  background-color: #dce8f6;
  transition: 0.4s ease-out;
}

.button.active {
  background-color: #094890;
  color: #fff !important;
  transition: 0.4s ease-out;
}

.button-collapsible.active .text,
.button-collapsible.active .material-icons-outlined,

.menu .button.active .text,
.menu .button.active .material-icons-outlined,
.menu .button.active .material-symbols-outlined {
  color: #fff !important;
}

.menu .menu-collapsible .button-collapsible .text {
  font-weight: bold;
  cursor: pointer;
  color: #707070;
  display: block;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.menu .menu-collapsible .button-collapsible {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: 0.2 ease-out;
  margin: 0 0.5rem;
  border-radius: 10px;
  cursor: pointer;
}

.menu-collapse .button {
  margin: 0rem 1rem;
}

.menu-collapse .button .material-icons-outlined,
.menu-collapse .button .material-symbols-outlined {
  color: #094890 !important;
  font-size: 1.5rem;
  margin-left: 0.1rem;
}

.menu-collapse .button .text {
  font-weight: normal;
  color: #094890;
  font-size: 14px;
  user-select: none;
}

.menu .sidebar-bottom-overlay {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-out;
  translate: 0px 0px;
  position: absolute;
  bottom: 0;
  padding-bottom: 0.5rem;
  width: 100%;
  overflow: hidden;
}

.sidebar-bottom-overlay .credit__box span {
  font-size: 14px;
}

.sidebar-bottom-overlay .credit__box small {
  font-size: 12px;
}

.buttons__mobile-sidebar {
  margin-top: 0.5rem;
}

.buttons__mobile-sidebar button .material-icons-outlined {
  font-size: 1.6rem !important;
  background-color: #707070;
  border-radius: 6px;
  padding: 0.3rem;
  color: #fff;
}

@media only screen and (max-height: 798px) {
  aside {
    width: 250px !important;
    font-size: 14px !important;
  }

  .menu-collapse .button .text {
    font-size: 14px !important;
  }

  .menu .menu-collapsible .button-collapsible {
    padding: 0.2rem 1rem !important;
  }

  .menu .button {
    padding: 0.4rem 1rem !important;
    margin: 0rem 0.5rem;
  }
}

@media only screen and (max-width: 991px) {
  aside {
    display: none;
    opacity: 0;
    visibility: hidden;
    width: 250px !important;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    border-left: var(--color-primary) 1px solid;
    translate: 300px;
    transition: 0.2s ease-out;
    overflow-y: hidden;
    height: 100vh;
  }

  .is-expanded {
    display: flex;
    flex-direction: column;
    visibility: visible;
    opacity: 1;
    translate: 0px;
    transition: 0.4s ease-out;
  }

  .menu-wrapper {
    padding-top: 0rem;
    /* height: -webkit-fill-available; */
  }
}

.logo-shift {
  margin-right: 5px !important;
}

.NEW-badge {
  border-radius: 0px 5px;
  font-size: 60%;
}

.bg-lightblue.NEW-badge {
  background-color: lightblue;
}

.font-weight-bold {
  font-weight: 600 !important;
}
</style>
