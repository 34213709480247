<template>
  <div>
    <b class="my-2">
      Welcome to NexLaw!
      {{
        expired
          ? "Your 7-days Free Trial has ended."
          : `You have ${days} left in your ${
              subscribed ? "subscription" : "trial"
            }.`
      }}
    </b>

    &nbsp;&nbsp;&nbsp;

    <button
      v-if="!subscribed && !opened && !expired"
      class="btn"
      @click="openSubscription"
    >
      {{ "Subscribe Now" }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subscribed: false,
      opened: false,
      expired: true,
      expiredDate: null,
    };
  },

  computed: {
    days() {
      if (this.expired) {
        return "0 day";
      }

      const days = Math.floor(
        new Date(this.expiredDate.getTime() - new Date().getTime()).getTime() /
          86400000
      );

      return `${days} day${days < 2 ? "" : "s"}`;
    },
  },

  methods: {
    openSubscription() {
      this.opened = true;
      this.$emit("open-subscription");
    },
  },

  created() {
    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

    if (userInfo) {
      this.subscribed = userInfo.manual_creating
        ? true
        : (
            ["nexmind.ai", "nexlaw.ai"].includes(userInfo.email.split("@")[1]) &&
            userInfo.not_have_plan_yet === null
            // bypass checking when the account is a dev account
            // which is usually ends with "nexmind.ai"/"nexlaw.ai" AND
            // not_have_plan_yet === null (due to manual creation of the account, where no value is provided to the key)
              ? true
              : userInfo.has_subscription
          );

      this.expired = userInfo.isExpired;

      this.expiredDate = userInfo.expiry_date
        ? new Date(userInfo.expiry_date)
        : null;
    }
  },
};
</script>

<style scoped>
div {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;

  color: white;
  background-color: var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
}

button {
  color: #094890;
  background-color: white;

  transition: 0.2s ease-out;
  &:hover {
    color: #094890;
    background-color: #dce8f6;
  }
}
</style>
