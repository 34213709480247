var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper-lg m-0"},[_vm._m(0),(_vm.current_plan)?_c('h3',{staticClass:"text-blue text-center mb-4 font-weight-bold"},[_vm._v(" "+_vm._s(`You${ _vm.subscribed ? "r current plan is" : "'ve been trialing" } ${ _vm.current_plan }`)+" ")]):_vm._e(),_c('div',{staticClass:"toggle-priceplan mb-4"},[_c('div',{staticClass:"subtitle"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.category),expression:"category"}],attrs:{"id":"monthly","type":"radio","name":"monthly","value":"monthly"},domProps:{"checked":_vm._q(_vm.category,"monthly")},on:{"change":function($event){_vm.category="monthly"}}}),_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.category),expression:"category"}],attrs:{"id":"yearly","type":"radio","name":"yearly","value":"yearly"},domProps:{"checked":_vm._q(_vm.category,"yearly")},on:{"change":function($event){_vm.category="yearly"}}}),_vm._m(2),_c('div',{staticClass:"subtitle"},[_vm._v("- Saved up to $1200")])]),_c('div',{staticClass:"plan-container"},[_c('div',{staticClass:"carousel"},[_vm._l((Math.ceil(_vm.plans.length / 2)),function(n){return _c('input',{key:`carousel-slide-activator-${n}`,staticClass:"carousel-activator",attrs:{"type":"radio","name":"carousel","id":`carousel-slide-activator-${n}`},domProps:{"checked":n === 1}})}),_vm._m(3),_vm._m(4),_c('div',{staticClass:"carousel-screen"},[_c('div',{staticClass:"carousel-track"},_vm._l((_vm.plans),function(plan,index){return _c('div',{key:'plan' + index,staticClass:"carousel__item w-50"},[_c('div',{class:[
                  'nexcard plan-card',
                  { 'popular-plan': plan.name == 'Complete' }, // change Popular Plan here (if needed)
                ]},[_vm._m(5,true),_c('div',{staticClass:"d-flex flex-column plan-content",style:(_vm.planContentStyle[index])},[_c('div',{staticClass:"row"},[(plan.id == 'custom')?_c('div',{staticClass:"col text-muted"},[_c('div',{domProps:{"innerHTML":_vm._s(plan.heading)}})]):_c('div',{staticClass:"col ori-price"},[_vm._v(" $"+_vm._s(plan.original_price[_vm.category])+" ")])]),(plan.id !== 'custom')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col currency"},[_c('b',{staticClass:"price"},[_vm._v(" $"+_vm._s(plan.price[_vm.category])+" ")]),_vm._v("USD / month ")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col plan-name"},[_c('b',[_vm._v(_vm._s(plan.name))])])]),_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('plan-perks-' + index),expression:"'plan-perks-' + index"}],staticClass:"py-2 w-100 d-flex justify-content-between"},[_c('b',[_vm._v("Subscription Benefits:")])])])]),_c('ul',_vm._l((plan.perks),function(perk,index){return _c('li',{key:index,class:[
                        'text-normal tick',
                        { 'text-hl': perk[1]?.split(' ').includes('hl') },
                      ]},[(perk[1]?.split(' ').includes('tooltip'))?_c('div',{staticClass:"tooltip-enabled-perk",staticStyle:{"cursor":"help","text-decoration-style":"dotted","text-decoration-line":"underline"},attrs:{"id":'tooltip-enabled-perk-' + index},domProps:{"innerHTML":_vm._s(perk[0])}}):_c('div',{domProps:{"innerHTML":_vm._s(perk[0])}}),(perk[1]?.split(' ').includes('small'))?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(perk[2])+" ")]):_vm._e(),_c('b-collapse',{attrs:{"id":'details-' + index}},[(perk[1]?.split(' ').includes('extra'))?_c('ul',{staticClass:"mt-2 normal-list"},[_c('li',{domProps:{"innerHTML":_vm._s(perk[3][0])}})]):_vm._e()]),(perk[1]?.split(' ').includes('extra'))?_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('details-' + index),expression:"'details-' + index"}],staticClass:"detail-btn text-muted",on:{"click":function($event){_vm.$set(
                            perk[3],
                            1,
                            perk[3][1] == '...See More'
                              ? '...See Less'
                              : '...See More'
                          )
                          _vm.adjustPlanHeight()}}},[_vm._v(" "+_vm._s(perk[3][1])+" ")]):_vm._e(),(perk[1]?.split(' ').includes('tooltip'))?_c('b-tooltip',{attrs:{"target":'tooltip-enabled-perk-' + index,"id":"subscribe-perk-tooltip","placement":"topright","noninteractive":""}},[_vm._v(" "+_vm._s(perk[4])+" ")]):_vm._e()],1)}),0)]),_c('div',[_c('div',{staticStyle:{"height":"60px"}}),(plan.id == 'custom')?_c('a',{attrs:{"href":plan.link,"target":"_blank"}},[_c('button',{staticClass:"btn-subscribe-basic"},[_vm._v("Contact Sales")])]):_c('button',{staticClass:"btn-subscribe-basic",on:{"click":function($event){return _vm.payPage(plan.plan_id, plan.price[_vm.category])}}},[_vm._v(" Subscribe ")])])])])}),0)])],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"text-blue text-center"},[_vm._v(" Choose your plan and continue using NexLaw "),_c('br'),_vm._v(" to accelerate your legal tasks ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"toggle-priceplan-yearly",attrs:{"for":"monthly"}},[_c('span',{staticClass:"text-blue m-auto"},[_vm._v("Monthly")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"toggle-priceplan-monthly flex-column",attrs:{"for":"yearly"}},[_c('span',{staticClass:"text-blue mx-auto"},[_vm._v("Yearly")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-controls"},[_c('label',{attrs:{"for":"carousel-slide-activator-2"}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("arrow_circle_right")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-controls"},[_c('label',{attrs:{"for":"carousel-slide-activator-1"}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("arrow_circle_left")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popular-tag"},[_c('b',[_vm._v("Popular")])])
}]

export { render, staticRenderFns }